import List from '../../components/list/List'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import PacmanLoader from 'react-spinners/PacmanLoader'
import { messageResources } from '../../assets/util/messageResources'
import noProfile from '../../assets/img/noProfile/noProfile.jpg'
import { useGetStudentsQuery } from '../../redux/student/studentSlice'
import InfoModal from '../../components/modal/InfoModal'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'
import ModalImage from "react-modal-image"


const StudentsList = () => {

    //console.log("|-----> StudentsList COMPONENT StudentsList <------|")

    useEffect(() => {
        window.scrollTo(0, 0);
    })


    const { roles } = useAuth()

    const columnsForAllStudents = [
        {
            title: "Picture", filtering: false, sorting: false, cellStyle: { width: "5%" }, render: rowData => <ModalImage
                small={rowData?.picture_path
                    ? rowData?.picture_path
                    : noProfile}
                large={rowData?.picture_path
                    ? rowData?.picture_path
                    : noProfile}
                alt={`${rowData?.name} ${rowData?.surname}`}
                hideDownload={true}
                hideZoom={true}
                className="pic-list"
            />
        },
        // { title: "Picture",  filtering: false, sorting:false, render:rowData=> <img src={rowData.picture_path ? rowData.picture_path : noProfile} style={{width: 58, borderRadius: '40%'}}/> }, 
        { title: "Name", field: "name", defaultSort: "asc", render: rowData => <Link to={`/students/${rowData._id}`}> {rowData.name} </Link> },
        { title: "Surname", field: "surname", defaultSort: "asc", render: rowData => <ClipboardCopy copyText={rowData.surname} /> },
        { title: "Email", field: "email", render: rowData => <ClipboardCopy copyText={rowData.email} /> },
        { title: "Contact Number", field: "contact_number", sorting: false, emptyValue: () => <em>N/A</em>, render: rowData => <ClipboardCopy copyText={rowData.contact_number} /> },
        { title: "Age", field: "age" },
        { title: "NIN", field: "nin", render: rowData => <ClipboardCopy copyText={rowData.nin} /> },
        { title: "Passport Expiry", field: "passport_expiry", type: 'date', dateSetting: { format: 'dd/MM/yyyy' }, cellStyle: { color: "blue" } },
        { title: "Year", field: "yearNum", render: rowData => <ClipboardCopy copyText={rowData.yearNum} /> },
        {
            title: "Actions", cellStyle: { width: "12%" }, render: rowData => <>

                {!roles?.includes(6) &&
                    <Link style={{ marginRight: '10px', marginBottom: '5px' }} className="btn btn-outline-success btn-circle" to={`/students/edit/${rowData._id}`}> AMEND </Link>
                }

                {(roles.includes(2) || roles.includes(5)) &&
                    <InfoModal
                        buttonText={messageResources.BUTTON.ARCH_STU}
                        stuId={rowData._id}
                        toDeleteId={rowData.idDoc}
                        methodType="archiveStu"
                        header={messageResources.MODAL.ARCH_STU_HEADER}
                        text={messageResources.MODAL.ARC_STU_TEXT}
                        toastText={messageResources.TOASTY.STU_ARCHIVED}
                    />
                }
            </>
        },
        // { title: "Passport Expiry",  field: "passport_expiry", type:"currency", currencySetting:{currencyCode:"USD",minimumFractionDigits:0}, cellStyle:{color:"blue"}},
    ];

    //const allOfThem = useSelector(selectAllStudents)

    // const allOfThem = useSelector((state) => selectAllStudents(state, {
    //     pollingInterval: 3000,
    // }))



    // pollingInterval TO MAKE CALLS TO BACK END TO UPDATE LIST
    // THIS IS NOT WORTH IT FOR THIS SYSTEM AS ARCHIVING STUDENTS IS NOT COMMON
    // const { 
    //     data: archived,
    //     isLoading,
    //     isSuccess 
    // } = useGetStudentsQuery(null, {
    //     pollingInterval: 60000
    // })

    const {
        data: archived,
        isLoading,
        isSuccess
    } = useGetStudentsQuery()

    let content;
    if (isLoading) {
        content = <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
            <PacmanLoader color="#84bdb1" size={70} />
        </div>
    } else if (isSuccess) {
        const { ids, entities } = archived


        const listContent = ids?.length
            ? ids.map(stuId => entities[stuId])
            : null


        content =
            <>
                <Breadcrumb pageTitle={`${messageResources.OPS_TITLES.STUDENTS} (${listContent.length} records)`} />
                <div className="row">
                    <div className="col-md-12">
                        <List titleForTable=""
                            dataForTable={JSON.parse(JSON.stringify(listContent))}
                            columnsForTable={columnsForAllStudents}
                            search={false}
                            colButton={true}
                            filter={true}
                            pageSize={listContent.length} />
                    </div>
                </div>
            </>

    }

    return (

        content

        // <>    
        //     <Breadcrumb pageTitle={`${messageResources.OPS_TITLES.STUDENTS} (${allOfThem.length} records)`}/>
        //     <div className="row">
        //         <div className="col-md-12">
        //             <List   titleForTable="" 
        //                     dataForTable={JSON.parse(JSON.stringify(allOfThem))} 
        //                     columnsForTable={columnsForAllStudents}
        //                     search={false}
        //                     colButton={true}
        //                     filter={true}
        //                     pageSize={allOfThem.length}/>
        //         </div>
        //     </div>
        // </>


    )
}

export default StudentsList
