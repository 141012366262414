import { useRef, useEffect, useState } from 'react'
import InputSelect from '../../components/forms/Input/InputSelect'
import Select from 'react-select'
import { useSelector } from 'react-redux';
import formatProgramme from '../../assets/util/formatProgramme'
import formatSubjects from '../../assets/util/formatSubjects';
import DatePicker from 'react-datepicker'
import Moment from 'moment'
import { toast } from 'react-toastify';
import { useForm, Controller, FormProvider } from "react-hook-form"
import { TextField } from "@mui/material";
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup"
import { currentAcademicYear } from "../../assets/util/academiYear"
import { addProgrammSchema, editProgrammSchema } from '../../assets/util/yupValidations'
import { messageResources } from '../../assets/util/messageResources'
import {
    aimOptions, specialistOptions,
    progamOptions, internshipActivitiesOptions,
    plannedEppOptions, cityGuildsOptions
} from '../../assets/util/selectOptions'
import {
    useAddProgrammeFromStudentMutation, selectStudentById,
    useEditProgrammeFromStudentMutation
} from '../../redux/student/studentSlice'


const Programme = () => {


    let programBtnRef = useRef();

    function SortArray(x, y) {
        return x.label.localeCompare(y.label);
    }

    const { studentId, action } = useParams()
    const location = useLocation();
    const studentInfo = useSelector((state) => selectStudentById(state, studentId))
    let navigate = useNavigate()

    const aimOptionsSorted = aimOptions.sort(SortArray);
    const cityGuildsOptionsSorted = cityGuildsOptions.sort(SortArray)
    const plannedEppOptionsSorted = plannedEppOptions.sort(SortArray)
    const internshipActivitiesOptionsSorted = internshipActivitiesOptions.sort(SortArray)

    const [addProgramFormStudent] = useAddProgrammeFromStudentMutation()
    const [editProgrammeFromStudent] = useEditProgrammeFromStudentMutation()

    const [viewCg, setViewCg] = useState()
    const [viewEep, setViewEep] = useState()
    const [viewAct, setViewAct] = useState()

    let buttonText;
    let programToEdit = {};
    const programmeFrom = location.state?.programmeTo;
    // console.log(programmeFrom)
    // console.log(programmeFrom?.startDate)
    // console.log(programmeFrom?.endDate)

    if (programmeFrom?._id) {

        programToEdit = formatProgramme(programmeFrom)

        const startDateProgram = new Date(programToEdit.startDate);
        const endDateProgram = new Date(programToEdit.endDate);

        programToEdit.startDate = startDateProgram.setFullYear(startDateProgram.getFullYear() + 1)
        programToEdit.endDate = endDateProgram.setFullYear(endDateProgram.getFullYear() + 1)

        // ADDING RETURNER SO SUBJECT DON'T INCLUDE SEVERAL FIELDS
        if (programToEdit.suppActivities && programToEdit.suppActivities.length > 0) {
            programToEdit.suppActivities = formatSubjects(programToEdit.suppActivities)
        }

        if (programToEdit.eepActivities && programToEdit.eepActivities.length > 0) {
            programToEdit.eepActivities = formatSubjects(programToEdit.eepActivities)
        }

        if (programToEdit.cgQualifications && programToEdit.cgQualifications.length > 0) {
            programToEdit.cgQualifications = formatSubjects(programToEdit.cgQualifications)
        }

        // console.log("AAAAAHORRA SI")
        // console.log(programToEdit)

    } else {


        if (studentInfo?.activeProgramme) {
            programToEdit = studentInfo?.activeProgramme
        } else {
            programToEdit.startDate = new Date();
            programToEdit.endDate = new Date();
        }

        //programToEdit = studentInfo?.activeProgramme
    }

    if (action == 'edit') {
        if (programmeFrom?._id) {
            buttonText = messageResources.BUTTON.ADD_PROG
        } else {
            buttonText = messageResources.BUTTON.AMEND_PROG
        }
    } else {
        buttonText = messageResources.BUTTON.ADD_PROG
    }


    useEffect(() => {

        var programmeButtonDiv = document.getElementById("fueraAca")

        if (programmeButtonDiv) programmeButtonDiv.style.display = 'none'

        if (programToEdit?.programme?.value == 1) {
            setViewCg(true)
            setViewEep(true)
            setViewAct(false)
        }else if(programToEdit?.programme?.value == 2 
                    || programToEdit?.programme?.value == 3){
            setViewCg(false)
            setViewEep(false)
            setViewAct(true)
        }

        reset(programToEdit);
        //setRender(true);
    }, []);


    const methods = useForm({
        resolver: yupResolver(programToEdit?._id ? editProgrammSchema : addProgrammSchema),
    });

    const {
        register,
        handleSubmit,
        reset,
        control,
        watch,
        getValues,
        formState: { errors },
    } = methods;

    console.log(errors)

    const programmeValue = watch("programme");
    // console.log("VALUE VALUE VALUE")
    // console.log(programmeValue)


    useEffect(() => {

        const subscription = watch((value, { name, type }) => {
            //console.log(value, name, type)

            if (value.programme?.value == 1) {
                setViewCg(true)
                setViewEep(true)
                setViewAct(false)
            }else if(value.programme?.value == 2 
                        || value.programme?.value == 3){
                setViewCg(false)
                setViewEep(false)
                setViewAct(true)
            }
        });

        // console.log(subscription)
        // return () => subscription.unsubscribe();
    }, [watch]);




    const onSubmit = async () => {

        if (programBtnRef.current) {
            programBtnRef.current.setAttribute("disabled", "disabled");
        }

        const values = getValues()

        values.stuId = studentId
        values.stuName = studentInfo.name + " " + studentInfo.surname

        let proceed = true
        let plus = ""
        const startDateProgram = new Date(values.startDate);
        const thisYear = currentAcademicYear()

        //console.log(thisYear)

        let thisYearForProgram
        let nextYearForProgram

        // MONTH OF START DATE where JAN = 0 and FEb = 1 ...
        let startProgramMonth = startDateProgram.getMonth()

        if (startProgramMonth > 7 && startProgramMonth < 11) {
            // MONTH BETWEEN SEPT AND DEC
            thisYearForProgram = startDateProgram.getFullYear()
            nextYearForProgram = startDateProgram.getFullYear() + 1
        } else if (startProgramMonth == 0 || (startProgramMonth > 0 && startProgramMonth < 8)) {
            // MONTH BETWEEN JAN AND AUG
            thisYearForProgram = startDateProgram.getFullYear() - 1
            nextYearForProgram = startDateProgram.getFullYear()
        }
        //console.log(thisYearForProgram)
        //console.log(nextYearForProgram)

        const startAcaYear = new Date(thisYearForProgram, 7, 31) // first of September of startDateProgram
        const endAcaYear = new Date(nextYearForProgram, 7, 30) // 31st of August next year for program

        //console.log(startAcaYear)
        //console.log(endAcaYear)

        const year = `${thisYearForProgram}/${nextYearForProgram}`

        //console.log(year)

        if (startDateProgram > startAcaYear && startDateProgram < endAcaYear) { // start date between academic year start and end
            //console.log("start date between academic year start and end")
            //year = `${parseInt(startDateProgram.getFullYear())}/${parseInt(startDateProgram.getFullYear())+1}`

            if (thisYear == year) {
                // we are adding a programme for this year
                //console.log("thisYear == year")
                values.isActive = true
            } else {
                // programme not for this year
                //console.log("programme not for this year")
                if (programmeFrom?._id) {
                    //console.log("adding returner before the year starts so always active")
                    values.isActive = true

                } else {

                    //console.log("adding programme not for this year and not returner")
                    let endYearFromThisAcaYear = thisYear.substring(5);

                    if (endYearFromThisAcaYear == thisYearForProgram) {
                        // end year from this aca year is the saem as the start year for programme aca year
                        // adding programme for next year but not returner so we activate the programme
                        values.isActive = true
                    } else {

                        values.isActive = false
                        plus = messageResources.TOASTY.HAS_PROGRAM_2
                    }

                }

            }

        } else if (startDateProgram < startAcaYear) { // start date before start of academic year we take previous year
            //console.log("start date before start of academic year")
            //year = `${parseInt(startDateProgram.getFullYear()-1)}/${parseInt(startDateProgram.getFullYear())}`
            values.isActive = false
            plus = messageResources.TOASTY.HAS_PROGRAM_2
        }

        values.academicYear = year

        // check more years added for the student
        if (studentInfo?.programmes?.length > 0) {
            studentInfo?.programmes?.map(prog => {
                // console.log(prog.academicYear)
                // console.log(year)
                if (prog.academicYear === year
                    && (action == 'add' || (action == 'edit' && programmeFrom?._id))) {
                    // THERE IS ALREADY A PROGRAM FOR THAT YEAR
                    // DON'T PROCEED IF ADDING NEW PROGRAM OR ADDING RETURNER
                    proceed = false
                }
            })
        }

        if (proceed) {

            if (values.eppHours) values.eppHours = parseFloat(values.eppHours)
            // if(values.qualyHours) values.qualyHours = parseFloat(values.qualyHours)
            if (values.learnerWeeks) values.learnerWeeks = parseFloat(values.learnerWeeks)
            if (values.weeklyHours) values.weeklyHours = parseFloat(values.weeklyHours)
            if (values.weeklyDays) values.weeklyDays = parseFloat(values.weeklyDays)

            values.totalHours = values.learnerWeeks * values.weeklyHours

            // only study program has quali houurs
            if (values.programme?.value == 1) {
                values.qualyHours = values.totalHours - values.eppHours;


                if (values.eepActivities && values.eepActivities.length > 0) {
                    values.eepActivities.map(one => {

                        one.startDate = values.startDate
                        one.endDate = values.endDate
                    })
                }

                if (values.cgQualifications && values.cgQualifications.length > 0) {
                    values.cgQualifications.map(one => {

                        one.startDate = values.startDate
                        one.endDate = values.endDate
                    })
                }


                if (values.suppActivities && values.suppActivities.length > 0) {
                    values.suppActivities = []
                }

            } else {

                values.qualyHours = null
                values.aim = null
                // for pre-supported and Non-esfa all hours are EPPhours
                values.eppHours = values.totalHours

                if (values.suppActivities && values.suppActivities.length > 0) {
                    values.suppActivities.map(one => {
                        one.startDate = values.startDate
                        one.endDate = values.endDate
                    })
                }

                if (values.eepActivities && values.eepActivities.length > 0) {
                    values.eepActivities = []
                }

                if (values.cgQualifications && values.cgQualifications.length > 0) {
                    values.cgQualifications = []
                }

            }


            // THE SERVER RECORDS THE DAY ONE HOUR EARLIER AS IT IS UTC and UK is UTC+1
            // IF WE DON'T FORMAT LIKE THIS THE DATE STORED WILL BE
            // ONE DAY BEFORE AT 23:00
            // WE FORMAT TO ADD A TIME IN THE MIDDLE OF THE DAY TO
            // AVOID THE SERVER STORING ONE DAY BEFORE
            values.endDate = Moment(values.endDate).format('DD MMMM YYYY 15:00:00')
            values.startDate = Moment(values.startDate).format('DD MMMM YYYY 15:00:00')

            //console.log(values)

            try {

                if (action == 'edit') {
                    editProgrammeFromStudent(values).unwrap()
                    toast.success(`${messageResources.TOASTY.PROGRAM_EDIT} ${year} ${plus}`, {
                        autoClose: 22002,
                    })
                } else {
                    await addProgramFormStudent(values).unwrap()
                    toast.success(`${messageResources.TOASTY.PROGRAM_ADDED} ${year} ${plus}`, {
                        autoClose: 22002,
                    })
                }

                if (programBtnRef.current) {
                    //console.log("------> DISABLE BUTTON <---------")
                    programBtnRef.current.removeAttribute("disabled");
                }

                navigate(`/students/${studentId}`)

            } catch (err) {
                console.log(err)
            }

        } else {

            toast.warn(`${messageResources.TOASTY.HAS_PROGRAM} ${year}. ${messageResources.TOASTY.HAS_PROGRAM_2}`, {
                autoClose: 22002,
            })

            reset(values);
        }



    }


    return (
        <div className="row pro" id="programmeDiv">

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="card card-box">
                        <div className="card-body " id="bar-parent">
                            <div className="row">
                                <div className="col-md-2" id="chooseProg" >
                                    <div className="form-group">
                                        <label>{messageResources.PROGRAMME.PROG}
                                            {errors?.programme && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>
                                        {/* TRY WATCH FROM USEFORM */}
                                        {/* LOGO ROOTS AND SHOOTS */}
                                        <Controller
                                            name="programme"
                                            control={control}
                                            render={({ field }) => (
                                                <Select options={progamOptions}
                                                    //defaultValue={studentInfo?.activeProgramme?.subjects}
                                                    //onChange={event => handleChange(event)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                {/* <InputSelect 
                                    slots="col-md-2" 
                                    label={messageResources.PROGRAMME.PROG} 
                                    errors={errors}
                                    control={control}
                                    inputName="programme"
                                    original={false}
                                    selectOptions={progamOptions}
                                /> */}
                                <InputSelect
                                    slots="col-md-2"
                                    label={messageResources.PROGRAMME.ESP}
                                    errors={errors}
                                    control={control}
                                    inputName="speciality"
                                    original={false}
                                    selectOptions={specialistOptions}
                                />
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>{messageResources.PROGRAMME.START}
                                            {errors.startDate && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>
                                        <Controller
                                            control={control}
                                            name='startDate'
                                            id="startDate"
                                            render={({ field }) => (
                                                <DatePicker

                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value ? field.value : Date.parse(programToEdit?.startDate)}
                                                    dateFormat="d MMMM yyyy"
                                                    placeholderText="Select Date"
                                                    peekNextMonth
                                                    portalId="root-portal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>{messageResources.PROGRAMME.END}
                                            {errors.endDate && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>
                                        <Controller
                                            control={control}
                                            name='endDate'
                                            render={({ field }) => (
                                                <DatePicker
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value ? field.value : programToEdit?.endDate}
                                                    dateFormat="d MMMM yyyy"
                                                    placeholderText="Select Date"
                                                    peekNextMonth
                                                    portalId="root-portal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                {viewEep &&
                                    <InputSelect
                                        slots="col-md-4"
                                        label={messageResources.PROGRAMME.AIM}
                                        errors={errors}
                                        control={control}
                                        inputName="aim"
                                        isClearable="true"
                                        original={false}
                                        selectOptions={aimOptionsSorted}
                                    />
                                }

                                {viewCg && <div className="col-md-6" >
                                    <div className="form-group">
                                        <label>{messageResources.PROGRAMME.CyG_QUALI}
                                            {errors?.condition && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>
                                        <Controller
                                            name="cgQualifications"
                                            control={control}
                                            render={({ field }) => (
                                                <Select options={cityGuildsOptionsSorted}
                                                    isMulti
                                                    defaultValue={studentInfo?.activeProgramme?.subjects}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>}

                                {viewAct &&
                                    <div className="col-md-6" >
                                        <div className="form-group">
                                            <label>{messageResources.PROGRAMME.PLAN_EEP}
                                                {errors?.condition && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                            </label>
                                            <Controller
                                                name="suppActivities"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select options={internshipActivitiesOptionsSorted}
                                                        isMulti
                                                        defaultValue={studentInfo?.activeProgramme?.subjects}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>}

                                {viewEep &&
                                    <div className="col-md-6" >
                                        <div className="form-group">
                                            <label>{messageResources.PROGRAMME.PLAN_EEP}
                                                {errors?.condition && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                            </label>
                                            <Controller
                                                name="eepActivities"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select options={plannedEppOptionsSorted}
                                                        isMulti
                                                        defaultValue={studentInfo?.activeProgramme?.subjects}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>}



                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>{messageResources.PROGRAMME.WEEKLY}
                                            {errors?.weeklyHours && <span className="errorSpan">  {errors?.weeklyHours?.message} </span>}
                                        </label>
                                        <Controller
                                            control={control}
                                            name="weeklyHours"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    //value={value}
                                                    fullWidth
                                                    placeholder={messageResources.PROGRAMME.WEEKLY}
                                                    //defaultValue={14}
                                                    type="number"
                                                    inputProps={{ min: "0", step: "0.5" }}
                                                    InputProps={{ disableUnderline: true, className: "form-control" }}

                                                />
                                            )}
                                        //defaultValue={defaultValue}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>{messageResources.PROGRAMME.DAYS}
                                            {errors?.weeklyDays && <span className="errorSpan">  {errors?.weeklyDays?.message} </span>}
                                        </label>
                                        <Controller
                                            control={control}
                                            name="weeklyDays"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    //value={value}
                                                    fullWidth
                                                    placeholder={messageResources.PROGRAMME.DAYS}
                                                    //defaultValue={14}
                                                    type="number"
                                                    inputProps={{ min: "0", step: "0.5" }}
                                                    InputProps={{ disableUnderline: true, className: "form-control" }}

                                                />
                                            )}
                                        //defaultValue={defaultValue}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>{messageResources.PROGRAMME.WEEKS}
                                            {errors?.learnerWeeks && <span className="errorSpan">  {errors?.learnerWeeks?.message} </span>}
                                        </label>
                                        <Controller
                                            control={control}
                                            name="learnerWeeks"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    //value={value}
                                                    fullWidth
                                                    placeholder={messageResources.PROGRAMME.WEEKS}
                                                    //defaultValue={14}
                                                    type="number"
                                                    inputProps={{ min: "0", step: "0.5" }}
                                                    InputProps={{ disableUnderline: true, className: "form-control" }}

                                                />
                                            )}
                                        //defaultValue={defaultValue}
                                        />
                                    </div>
                                </div>

                                {/* {viewEep && <div className="col-md-2">
                                    <div className="form-group">
                                        <label>{messageResources.PROGRAMME.FORQUALY}
                                            {errors?.qualyHours && <span className="errorSpan">  {errors?.qualyHours?.message} </span>}
                                        </label>
                                    <Controller
                                        control={control}
                                        name="qualyHours"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                //value={value}
                                                fullWidth
                                                placeholder={messageResources.PROGRAMME.FORQUALY}
                                                //defaultValue={14}
                                                type="number"
                                                inputProps={{ min: "0", step: "0.5"}}
                                                InputProps={{ disableUnderline: true, className: "form-control"}}
                                                
                                            />
                                        )}
                                        //defaultValue={defaultValue}
                                    />
                                    </div>
                                </div>} */}
                                {viewEep &&
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>{messageResources.PROGRAMME.EPP}
                                                {errors?.eppHours && <span className="errorSpan">  {errors?.eppHours?.message} </span>}
                                            </label>
                                            <Controller
                                                control={control}
                                                name="eppHours"
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        //value={value}
                                                        fullWidth
                                                        placeholder={messageResources.PROGRAMME.EPP}
                                                        //defaultValue={14}
                                                        type="number"
                                                        inputProps={{ min: "0", step: "0.5" }}
                                                        InputProps={{ disableUnderline: true, className: "form-control" }}

                                                    />
                                                )}
                                            //defaultValue={defaultValue}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{messageResources.PROGRAMME.TIME}</label>
                                        <input 	type="text" 
                                            className="form-control"
                                            {...register('timetable')}
                                            placeholder={messageResources.PROGRAMME.TIME} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>{messageResources.COMMON.NOTES}
                                            {/* {errors?.contact_number && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                        </label>
                                        <textarea
                                            rows="4"
                                            className="form-control"
                                            {...register('notes')}
                                            placeholder={messageResources.COMMON.NOTES} />
                                    </div>
                                </div>



                                <div className="col-md-1">
                                    <button type="submit"
                                        style={{ marginTop: "25px" }}
                                        className="btn btn-primary"
                                        ref={programBtnRef}
                                        onClick={methods.handleSubmit(onSubmit)}
                                    >
                                        {buttonText}

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>

    )


}

export default Programme