import { messageResources } from "../../assets/util/messageResources"


const printListComa = (list) => {


    var toPrint = "";
    list.map((item) => {
        toPrint += `${item.label}, `
        //console.log(toPrint)
    })

    if (toPrint.trim().endsWith(',')) { return toPrint.slice(0, -2) } else { return toPrint }

}


const formatStudent = (student) => {

    student.id = student._id // THIS LINE IS ESSENTIAL FRO NORMALIZATION TO WORK WITH MONGO DB

    const docsForList = student.gooDocuments && student.gooDocuments.map(document => (

        {
            type: document.type.value,
            year: document.year,
            url_link: document.url_link,
            description: document.description,
            createdAt: document.createdAt,
            idDoc: document._id
        }


    ))


    var carrersDocs = []
    var docTypes = [messageResources.DOCUMENTS.REVIEW_1, messageResources.DOCUMENTS.REVIEW_2,
    messageResources.DOCUMENTS.REVIEW_3, messageResources.DOCUMENTS.RISK_1,
    messageResources.DOCUMENTS.RISK_2, messageResources.DOCUMENTS.RISK_3,
    messageResources.DOCUMENTS.CASE_1, messageResources.DOCUMENTS.CASE_2,
    messageResources.DOCUMENTS.CASE_3, messageResources.DOCUMENTS.CASE_4,
    messageResources.DOCUMENTS.CASE_5, messageResources.DOCUMENTS.CASE_6,
    messageResources.DOCUMENTS.CONTRACT_1, messageResources.DOCUMENTS.CONTRACT_2,
    messageResources.DOCUMENTS.CONTRACT_3
    ]


    docsForList.map(document => {
        if (docTypes.includes(document.type)) {
            carrersDocs.push(document)
        }
    }
    )

    student.carrersDocsToShow = carrersDocs

    var carDocsUploaded = ""

    if (student.careers) {

        if (student.careers.cv_link) {
            carDocsUploaded += " CV,"
        }

        if (student.careers.voc_link) {
            carDocsUploaded += " VP,"
        }

        if (student.careers.plan_link) {
            carDocsUploaded += " IAG,"
        }

    }

    if (carDocsUploaded === "") {
        carDocsUploaded = "N/A"
    }

    if (carDocsUploaded.endsWith(',')) {
        carDocsUploaded = carDocsUploaded.substring(0, carDocsUploaded.length - 1)
    }

    student.carDocs = carDocsUploaded

    student.gooDocuments && student.gooDocuments.length > 0 && student.gooDocuments.map(docu => {


        if (docu.type.value == `${messageResources.DOCUMENTS.PASSPORT}`) {
            student.pass_path = docu.url_link
        } else if (docu.type.value == `${messageResources.DOCUMENTS.BC}`) {
            student.cert_path = docu.url_link
        } else if (docu.type.value == `${messageResources.DOCUMENTS.EHCP}`) {
            student.doc_link = docu.url_link
        } else if (docu.type.value == `${messageResources.DOCUMENTS.PROFILE}`) {
            student.profile_link = docu.url_link
        } else if (docu.type.value == `${messageResources.DOCUMENTS.INITIAL}`) {
            student.assessment.ass_link = docu.url_link
        } else if (docu.type.value == `${messageResources.DOCUMENTS.ANNUAL}`) {
            student.annual_link = docu.url_link
        } else if (docu.type.value == `${messageResources.DOCUMENTS.DISCRETIONARY_PROOF}`) {
            student.bursary.discretionary_link = docu.url_link
        } else if (docu.type.value == `${messageResources.DOCUMENTS.VULNERABLE_PROOF}`) {
            student.bursary.vulnerable_link = docu.url_link
        }

    })


    if (student.programmes?.length > 0) {
        student.yearNum = "Year " + student.programmes?.length
    } else {
        student.yearNum = "No Programmes"
    }


    student.programmes?.map(program => {

        if (program.isActive) {

            student.activeProgramme = {}
            student.activeProgramme._id = program._id
            student.activeProgramme.endDate = Date.parse(program.endDate)
            student.activeProgramme.startDate = Date.parse(program.startDate)
            student.activeProgramme.weeklyHours = program.weeklyHours
            student.activeProgramme.weeklyDays = program.weeklyDays
            student.activeProgramme.learnerWeeks = program.learnerWeeks
            student.activeProgramme.totalHours = program.totalHours
            student.activeProgramme.qualyHours = program.qualyHours
            student.activeProgramme.eppHours = program.eppHours
            student.activeProgramme.courseNum = program.courseNum
            student.activeProgramme.academicYear = program.academicYear
            student.activeProgramme.notes = program.notes
            student.activeProgramme.timetable = program.timetable
            student.activeProgramme.priorAtt = {}
            student.activeProgramme.priorAtt.label = program.priorAtt?.label
            student.activeProgramme.priorAtt.value = program.priorAtt?.value
            student.activeProgramme.programme = {}
            student.activeProgramme.programme.label = program.programme?.label
            student.activeProgramme.programme.value = program.programme?.value
            student.activeProgramme.speciality = {}
            student.activeProgramme.speciality.label = program.speciality?.label
            student.activeProgramme.speciality.value = program.speciality?.value
            student.activeProgramme.aim = {}
            student.activeProgramme.aim.label = program.aim?.label
            student.activeProgramme.aim.value = program.aim?.value
            student.activeProgramme.destination = {}
            student.activeProgramme.destination.label = program.destination?.label
            student.activeProgramme.destination.value = program.destination?.value
            student.activeProgramme.suppActivities = program.suppActivities
            student.activeProgramme.eepActivities = program.eepActivities
            student.activeProgramme.cgQualifications = program.cgQualifications


        }
    })

    // NOKs FOR THE LIST TO EXPORT
    let kinColumn = ""
    let kinEmails = ""
    if (student.kins?.length > 0) {

        student.kins?.map(family => {
            kinEmails += family.email + ";"
            kinColumn += "[" + family.name + " " + family.surname + " " + family.email + "(" + family.kinship.label + ")] "
        })
    }
    student.kinToShow = kinColumn
    student.kinEm = kinEmails

    // const clonedObj = JSON.parse(JSON.stringify(docsForList));
    student.docListToShow = docsForList

    student.nokAddress = {}
    student.nokAddress.address1 = student.address.address1
    student.nokAddress.address2 = student.address.address2
    student.nokAddress.postcode = student.address.postcode

    if (student.medical && student.medical.condition && student.medical.condition.length == 0) {
        student.medical = null
    } else if (student.medical) {
        // I have to do this because exporting to pdf from a list prints [Object]
        student.medicalCon = printListComa(student.medical.condition)

        if (student.medical.dietary && student.medical.dietary.length > 0) {
            student.medicalDiet = printListComa(student.medical.dietary)

        }

    }


    if (student.ehcp) {

        //student.lastAnualReview = Date.parse(student.ehcp.last_review)
        student.nextAnualReview = Date.parse(student.ehcp.next_review)

        if (student.ehcp.year_one) student.year_one = student.ehcp.year_one
        if (student.ehcp.year_two) student.year_two = student.ehcp.year_two
        if (student.ehcp.year_three) student.year_three = student.ehcp.year_three
        if (student.ehcp.year_four) student.year_four = student.ehcp.year_four
        if (student.ehcp.year_five) student.year_five = student.ehcp.year_five

        if (!student.ehcp.renewal && !student.ehcp.date && !student.ehcp.last_review && !student.ehcp.next_review) {
            student.ehcp = null
        }

    }

    if (student.assessment) {

        if (student.assessment.engDate) {
            student.assessment.engDate = Date.parse(student.assessment.engDate)
        }

        if (student.assessment.mathsDate) {
            student.assessment.mathsDate = Date.parse(student.assessment.mathsDate)
        }
    }


    return student

}

export default formatStudent