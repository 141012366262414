export const messageResources = {

    COMMON: {
        NAME: "Name",
        YES: "Yes",
        NO: "No",
        SUBJECT: "Subject",
        SURNAME: "Surname",
        PICTURE: "Picture",
        ADDRESS: "Address",
        DOB: "Date of Birth",
        DOBS: "DOB",
        AGE: "Age",
        GENDER: "Gender",
        PRONOUN: "Preferred Pronoun",
        COMMENTS: "Comments",
        SELECT_DATE: "Select Date",
        PASSWORD: "Password",
        CONFIRM_PW: "Confirm Password",
        EMAIL: "Email",
        SELECT: "Select an Option",
        SUBMIT: "Submit",
        STUDENTS: "Students",
        STEP1: "Step 1",
        STEP2: "Step 2",
        ROWS: "Total rows",
        NA: "N/A",
        NO_LINK: "No Link Included",
        OPEN: "CLICK TO OPEN",
        NOTES: "Notes",
        ACTIONS: "Actions",
        NONE: "None",
        ONE: "One",
        TWO: "Two",
        THREE: "Three",
        PASTE_LINK: "Google Link Here",
        TOTAL: "Total",
        DOCUMENTS: "Documents",
        PAGINATIOMN: "Please Use Pagination at the botton of the list."

    },

    GENDER: {
        MALE: "Male",
        FEMALE: "Female",
        NON: "Non Binary"
    },

    MODAL: {
        DEL_DOC: "Deleting Document",
        DEL_DOC_TEXT: "Are you sure you want to delete this document?",
        EDIT_DOC: "Editing Document",
        ADD_DOC: "Adding Document",
        DEL_NOK: "Deleting Next of Kin",
        DEL_NOK_TEXT: "Are you sure you want to delete this next of kin?",
        AMEND_NOK: "Amending Next of Kin",
        ADD_NOK: "Adding Next of Kin",
        ADD_SUPPORT: "Add Support Worker",
        AMEND_SUPPORT: "Amend Support Worker",
        DELETE_SUPPORT: "Delete Support Worker",
        DEL_SUPPORT_TEXT: "Are you sure you want to delete support worker information?",
        ADD_TAXI: "Add Travel Information",
        AMEND_TAXI: "Amend Travel Information",
        DELETE_TAXI: "Delete Travel Information",
        DEL_TAXI_TEXT: "Are you sure you want to delete the travel information?",
        NA_TAXI: "",
        NO_TAXI: "No Travel Info Needed",
        NO_TAXI_TEXT: "Are you sure the travel information is not needed for this student? If you click on 'yes' the notification will be processed but travel information will not be added to the student.",
        ADD_MEDICAL: "Add Medical Information",
        ADD_BURSARY: "Add Bursary Information",
        AMEND_MEDICAL: "Amend Medical Information",
        AMEND_BURSARY: "Amend Bursary Information",
        DELETE_MEDICAL: "Delete Medical Information",
        DEL_MEDICAL_TEXT: "Are you sure you want to delete the medical information?",
        NO_MEDICAL: "No Medical Info Needed",
        NO_NIN: "No NIN Provided",
        NO_MEDICAL_TEXT: "Are you sure the medical information is not needed for this student? If you click on 'yes' the notification will be processed but medical information will not be added to the student.",
        NO_NIN_TEXT: "Are you sure the national insurance number is not needed for this student? If you click on 'yes' the notification will be processed but NIN information will not be added to the student.",
        CHANGE_PW: "Change Password",
        OLD_PW: "Current Password",
        NEW_PW: "New Password",
        RETYPE_PW: "Retype New Password",
        EDIT_PROFILE: "Edit Profile",
        ADD_CAREERS: "Add Careers Information",
        AMEND_CAREERS: "Amend Careers Information",
        ARCH_STU_HEADER: "Archive Student",
        ARC_STU_TEXT: "Are you sure you want archive this student?",
        ACTI_STU_HEADER: "Activate Student",
        ACTI_STU_TEXT: "Are you sure you want activate this student?",
        MARK_SUBJECT: "Mark / Edit",
        ADD_DEST: "Add Destination"


    },

    NOK: {
        KINSHIP: "Kinship",
    },

    PROGRAMME: {
        START: "Start Date",
        END: "End Date",
        PROG: "Programme",
        ESP: "Vocational Choice",
        WEEKLY: "Weekly Hours",
        DAYS: "Days per Week",
        WEEKS: "Learner Weeks",
        TOTAL: "Total Hours",
        FORQUALY: "Qualification Hours",
        EPP: "EEP Hours",
        PRIOR: "Prior Attainment",
        CGSE_ENG: "GCSE English",
        CGSE_MATH: "GCSE Maths",
        AIM: "Core Aim",
        COURSE: "Course Number",
        MATH_EXEM: "Maths Exemption",
        ENG_EXEM: "English Exemption",
        SUBJECTS: "Subjects",
        CyG_QUALI: "Planned City and Guilds Qualifications",
        SUP_ACTIS: "Supported Internship Activities",
        PLAN_EEP: "Planed EEP",
        YEAR: "Academic Year ",
        DESTINATION: "Destination",
        EMPL: "Employer/Educator",
        TIME: "Timetable",
        DATE_START: "Course Start: "
    },

    ASSESSMENT: {
        TYPE: "Type of Assessment",
        ENG_COMPLETED: "English IA Completed",
        ENG_RESULTS: "English Results",
        MATHS_COMPLETED: "Maths Date Completed",
        MATHS_RESULTS: "Maths Results",
        RESULTS: "Results",
        DATE: "Date Completed",
        LINK: "Initial Assessment Link"
    },

    SUBJECT: {
        COMPLETED: "Completed",
        ACHIEVEMENT: "Achievement",
        ACTUAL: "Actual End Date",
        HOURS: "Planned Hours",
        REF: "Ref",
        NAME: "Subject's Name",
        OUTCOME: "Outcome"
    },

    CAREERS: {
        CV: "Learner's CV",
        VOCATIONAL: "Vocational Profile",
        PLAN: "IAG Plan",
        CASE: "Case Study",
        REVIEWS: "Employer Reviews",
        DOCS: "Careers Documents"
    },


    REFERRAL: {
        HIGH: 'Highshore',
        ELM: 'Elm Court',
        BOSCO: 'Bosco',
        LANS: 'Lansdowne',
        CARE: 'Care Trade',
        SPA: 'Spa',
        FAM: 'Family',
        VANGUARD: 'Vanguard',
        LA: 'Local Authority',
        LB: 'Lilian Baylis'
    },

    BUTTON: {

        PREV: "Prev",
        NEXT: "Next",
        ADD: "ADD",
        DELETE: "DELETE",
        PROCESS: "PROCESS",
        CANCEL: "CANCEL",
        AMEND: "AMEND",
        SUBMIT: "Submit",
        SEARCH: "Search",
        SUBMIT_FORM: "Submit Form",
        EDIT: "EDIT",
        ADD_KIN: "Add Next of Kin",
        ADD_DOC: "Add New Document",
        ADD_DEST: "Add Destination",
        ADD_DOC_CARR: "Add New Careers Document",
        ADD_EHCP: "Add EHCP Info",
        ADD_ASSESSMENT: "Add Assessment Info",
        ADD_NOK: "Add Next of Kin",
        ADD_PROGRAMME: "Add Programme",
        ADD_RETURNER: "Add Returner",
        EDIT_PROGRAMME: "Amend Programme",
        EDIT_EHCP: "Edit EHCP Info",
        EDIT_ASSESSMENT: "Edit Assessment Info",
        ADD_TAXI: "Travel Information",
        SUPPORT: "Support Worker",
        EHCP: "EHCP",
        PROFILE: "STUDENT PROFILE",
        PDF_PROGR: "PRINT PLANNED HOURS",
        GO_BACK: "Go Back",
        ADD_PROG: "Add Programme",
        AMEND_PROG: "Amend Programme",
        ADD_ASS: "Add Assessment",
        AMEND_ASS: "Amend Assessment",
        ADD_CAREER: "Add Careers",
        ARCH_STU: "ARCHIVE",
        ACTI_STU: "ACTIVATE",
        MARK: "MARK / EDIT",
        ILP: "PRINT ILP",
        LAND: "LANYARD"

    },

    CONTACT_INFO: {
        HEADER: "Contact Information",
        ADDRESS_1: "Address 1",
        PHONE: "Phone",
        ADDRESS_2: "Address 2",
        POSTCODE: "Postcode",
        NUMBER: "Contact Number",
        ATT_NUMBER: "Att Number",
        CITY: "City"
    },


    STAFF: {
        POSITION: "Position",
        PW: "Change PassWord",
        ROLE: "Role",
        MY: "My Profile",
        DISABLE: "Inactive Staff",
        DEACTIVATE: "Deactivate Staff",
        ACTIVE: "Active Staff Members",
        DISABLED: "Inactive Staff Members"
    },

    STUDENT: {
        PERSONAL_DATA: "Personal Data",
        NAME: "Student Name",
        ETHNICITY: "Ethnicity",
        NIN: "National Insurance No.",
        NINS: "NIN",
        LANG: "Languages",
        REF: "Referral",
        LRS: "LRS ULN Link",
        PASSPORT_NUM: "Passport No.",
        PASSPORT_EXPIRY: "Passport Expiry Date",
        PASSPORT_UK: "Passport Issued in the UK",
        PASS_LINK: "Passport Document Link",
        PASSPORT_DOC: "Passport Document",
        BOROUGH: "Borough",
        EHCP: "EHCP",
        EHCP_LINK: "EHCP Document Link",
        ANNUAL_LINK: "Annual Review Document Link",
        EHCP_DOC: "EHCP Document",
        EHCP_DATE: "EHCP Date",
        EHCP_RENEWAL: "Renewal Date",
        LD: "Disabilty/Diagnosis List",
        ANNUAL_REVIEW: "Last Annual Review",
        NEXT_REVIEW: "Next Annual review",
        EHCP_LAST: "EHCP approved?",
        PHOTO: "Photo Consent",
        ABOUT: "About Student",
        DOB_LINK: "Birth Certificate Link",
        LINK_PLACEHOLDER: "Link from Google Docs when document uploaded",
        DOB_CERT: "Birth Certificate",
        MEDICAL: "Medical",
        PROGRAMME: "Programme",
        ASSESSMENT: "Assessment",
        REVIEW_ONE: "Annual Review One",
        REVIEW_TWO: "Annual Review Two",
        REVIEW_THREE: "Annual Review Three",
        REVIEW_FOUR: "Annual Review Four",
        REVIEW_FIVE: "Annual Review Five",
        CAREER: "Careers",
        BURSARY: "Bursary",
        INDEPENDENT: "Independent Traveller",
        NON_INDEPEN: "This student is a non independent traveller. Click on the travel tab for more information",
        PASS_EXPIRED: "ATTENTION: This student's passport has EXPIRED, please update",
        HAS_ZIP: "This student has a disabled person's Freedom Pass or a Zipcard. More infomation in Bursary tab",
    },

    MEDICAL: {
        CONDITION: "Medical Condition",
        MEDICATION: "Medication",
        ADMNISTRATION: "Medication and Administration",
        NOTES: "Notes",
        DIETARY: "Dietary Requirements and Allergies"

    },

    BURSARY: {
        MEALS: "Free School Meals at previous school",
        ZIPCARD: "Disabled Person's Freedom Pass or a Zipcard",
        DISCRETIONARY: "Eligible for Discretionary Bursary",
        VULNERABLE: "Eligible for Vulnerable Bursary",
        DIETARY: "Dietary Requirements"
    },

    CHART: {
        GENDER: "Gender Percentages",
        ETH: "Ethnicity Percentages",
        BORO: "Borough Percentages",
        SPEC: "Vocational Choice Percentages",
        LANGS: "Languages Spoken",
        PROG: "Programme Percentages",
        BUR: "Eligible for Bursary",
        YEAR: "Academic Year",
        DESTI: "Destination Percentages",
        RETEN: "Retention",
        REF: "Referral Percentages"
    },

    DOCUMENTS: {
        TYPE: "Document Type",
        YEAR: "Year",
        LINK: "Link from Google Docs",
        DESCRIPTION: "Description",
        EHCP: "EHCP Record",
        PROFILE: "One Page Profile",
        LRS: "LRS ULN Record",
        BC: "Birth Certificate",
        PASSPORT: "Passport",
        AUTO_DESC: "Doc added by editing the student",
        AUTO_DESC_REG: "Doc added when registering the student",
        DOCS_MENU: "Documents",
        INITIAL: "Initial Assessment",
        REVIEW_1: "Employer Review One",
        REVIEW_2: "Employer Review Two",
        REVIEW_3: "Employer Review Three",
        RISK_1: "WP Risk Assessment One",
        RISK_2: "WP Risk Assessment Two",
        RISK_3: "WP Risk Assessment Three",
        CASE_1: "Case Study One",
        CASE_2: "Case Study Two",
        CASE_3: "Case Study Three",
        CASE_4: "Case Study Four",
        CASE_5: "Case Study Five",
        CASE_6: "Case Study Six",
        CONTRACT_1: "Contract One",
        CONTRACT_2: "Contract Two",
        CONTRACT_3: "Contract Three",
        ANNUAL: "Annual Review",
        DISCRETIONARY_PROOF: "Discretionary Bursary Evidence",
        VULNERABLE_PROOF: "Vulnerable Bursary Evidence"

    },

    TAXI: {
        COMPANY: "Company",

    },

    REGISTRATION: {
        REGISTER: "Register",
        CONF_PASS: "Confirm Password",
        ALREADY: "You already have an account?",
        RE_EMAIL: "Retype Email"
    },

    STATABLE: {
        EXPECT: "Expected",
        ACHIV: "Achieved",
        PERCEN: "Percentage",
        TOTAL: "Total",
        QUALI: "Qualification",
        NON: "Non regulated",
        PRE: "Pre-SI",
        HORTI: "Horticulture Level 1",
        FLORAL: "Floral Design",
        ENTRY: "Horticulture Entry",
        RETAIL: "Retail",
        EMPLOY: "Employability",
        MATHS: "Mathematics",
        ENG: "English",
        ICT: "ICT",
        TOTAL: "Total"
    },

    LOGIN: {
        SIGN: "Sign in",
        LOGIN: "Login",
        FORGOT: "I Forgot My Password",
        REGISTER: "Register New Account",
        TRUST: "Trust this device",
        RESET: "Reset Password",
        TYPE: "Please type your email address"
    },

    OPS_TITLES: {
        PROFILE: "Profile",
        ACADEMIC: "Academic Year",
        ONGOING: "Current",
        FINISHED: "Previous",
        ACHIEVEMENTS: "Achievements",
        ARCHIVED: "Archived",
        STUDYING: "Students Studying",
        COMPLETED: "Finished Programmes",
        PR_ARCHIVED: "Programmes from Archived Students",
        LOGOUT: "Log Out",
        SEROOTS: "Seroots MS",
        STUDENTS: "Student List",
        STU_DATA: "Students Data",
        EHCP_DATA: "EHCP Students' Dates",
        ARCHIVE: "Archive",
        STU_ARCH: "Students Archived",
        HOURS_CHART: "Charts",
        EXPORT: "Student Data",
        EHCP_DATES: "EHCP Dates",
        STU_CARDS: "Student Cards",
        STU_PROFILE: "Student Profile",
        STU_ADD: "Add Student",
        DOCUMENTS: "Documents",
        SUPPORT: "Sup. Worker",
        TAXI: "Travel Inf.",
        STU_EDIT: "Edit Student",
        EDIT: "Edit",
        DETAILS: "Details",
        PROFILE: "Profile",
        STAFFS: "Staff List",
        STAFF_MEMBERS: "Staff Members",
        STAFF_CARDS: "Staff Cards",
        STAFF_ADD: "Add Staff Member",
        STAFF_PROFILE: "Staff Member Profile",
        DASHBOARD: "Dashboard",
        NOTIFICATIONS: "Notifications",
        ADD_NOK: "Add Next of Kin for ",
        NOK: "Next of Kin",
        ERROR: "SOMETHING WENT WRONG",
        ALL_DOCS: "All Students' Documents",
        SUBJECTS: "Programme for ",
        CHARTS: "Charts",
        ADMIN: "Admin",
        LOGS: "Tracker",
        USER_TRACK: "Tracker: Search Users' Actions",
        CHAR_ACADEMIC: "Progs. Per Year",
        CHART_YEAR: "Year Comparison",
        STUDENTS: "Students' Details",
        COMPARISON: "Achievement Year Comparison"

    },

    TRACKER: {

        TYPE: "Action Type",
        DATE: "Date",
        STAFF: "Staff",
        STUDENT: "Student"

    },

    VALIDATIONS: {
        REQUIRED: "is required",
        REQUIRED_EMAIL: "is required as a valid email",
        REQUIRED_NUMBER: "is required as number",
        PASSWORDS_MATCH: "Passwords don't match",
        EMAILS_MATCH: "Emails don't match"
    },

    TOASTY: {
        VERIFIED: "Account successfully verified. You can sign in now",
        LOGIN_ERROR: "Email or password incorrect. Please try again",
        DISABLED: "This user is disabled. Please contact the administrator.",
        LOGIN_SUCCESS: "Welcome back to Seroots!!",
        LOGGED_OUT: "Session closed. See you soon!!",
        TOKEN_EXPIRED: "Session expired. Please sign in",
        REGISTERED_SUCCESS: "Account successfully registered. Please check your inbox to activate",
        REGISTER_ERROR: "The registration was not complete.",
        ALREADY_REGISTERED: "This email address is already registered",
        STUDENT_ADDED: "Student Added Successfully",
        STUDENT_EDITED: "Student Edited Successfully",
        STUDENT_NOT_ADDED: "Error Adding Student.",
        DOCUMENT_ADDED: "Document Added Successfully",
        DOCUMENT_EDITED: "Document Edited Successfully",
        DOCUMENT_DELETED: "Document Deleted Successfully",
        NOK_ADDED: "Next of Kin Added Successfully",
        NOK_DELETED: "Next of Kin Deleted Successfully",
        NOK_EDIT: "Next of Kin Amended Successfully",
        SUPPORT_ADDED: "Support Worker Added to the Student Successfully",
        SUPPORT_EDIT: "Support Worker Amended Successfully",
        SUPPORT_DELETED: "Support Worker Information Deleted Successfully",
        TAXI_ADDED: "Travel Information Added to the Student Successfully",
        TAXI_EDIT: "Travel Information Amended Successfully",
        BURSARY_ADDED: "Bursary Information Added to the Student Successfully",
        BURSARY_EDIT: "Bursary Information Amended Successfully",
        TAXI_DELETED: "Travel Information Deleted Successfully",
        VERIFIED: "Account successfully verified. You can log in now",
        VERIFIED_BEFORE: "Account record doesn't match or has been verified before. Please try to log in or register",
        LINKED_EXPIRED: "The verification link has expired. Please register again",
        STAFF_REGISTERED: "This email address is already registered as a user in the system. You can proceed and complete the information",
        STAFF_EDITED: "Staff member updated successfully",
        STAFF_NOT_ADDED: "Error Adding Staff member. ",
        CONTANT_ADMIN: "Please try again or contact your administrator",
        NOTI_PROCESS: "Notification Processed Successfully",
        MEDICAL_ADDED: "Medical Information Added to the Student Successfully",
        MEDICAL_EDIT: "Medical Information Amended Successfully",
        NOTIS_PENDING: "You have notifications pending to process. Please act accordingly.",
        CAREERS_ADDED: "Careers Information Added to the Student Successfully",
        CAREERS_EDIT: "Careers Information Amended Successfully",
        ASSESSMENT_ADDED: "Assessment Information Added to the Student Successfully",
        ASSESSMENT_EDIT: "Assessment Information Amended Successfully",
        PROGRAM_ADDED: "Programme Information Added to the Student for ",
        PROGRAM_EDIT: "Programme Information Amended for ",
        STU_ARCHIVED: "Student Archived Successfully",
        STU_ACTIVATED: "Student Activated Successfully",
        SUBJECT_EDITED: "Subject Edited Successfully",
        PROGRAM_MARKED: "This programme is now fully marked and moved to the 'previous programmes' list shown below",
        HAS_PROGRAM: "This student already has a programme added for ",
        HAS_PROGRAM_2: "You will find it in previous academic programme list in the menu",
        RESET_PW: "Please check your inbox. We have sent you a link to reset your password",
        PW_RESETED: "Your password has been reset. You can now access SeRoots",
        RESET_NO_USER: "If you want to reset your passwrod please click on the link below",
        NO_RESET: "Please don't copy & paste URLs. Click on 'I forgot my password' to reset",
        NOT_REGISTERED: "This email is not registered in SEROOTS",
        DEST_ADDED: "Destination Added Successfully to the Programme",
        SESSION_EXPIRED: "You Sesion has Expired. Pleas Sign In Again"
    },

    NOTIFICATIONS: {
        TYPE_PASS: "Student Passport Details",
        PENDING: "Pending Notifications",
        PROCESSED: "Notifications Processed by You",
        NO_NOTIS: "NOTHING TO PROCESS RIGHT NOW. YOU MAY HAVE A CUP OF TEA",
        NO_PROCESSED: "YOU HAVEN'T PROCESSED ANY NOTIFICATION YET",
        NO_PENDING: "THERE ARE NOT PENDING NOTIFICATIONS TO PROCESS",
        ALLPROCESSED: "All Processed Notifications",
        ALLPENDING: "All Pending Notifications"
    },

    BANNER: {
        NO_DOCS: "THERE ARE NO DOCUMENTS UPLOADED FOR THIS STUDENT YET",
        NO_DOCS_SECTION: "THERE ARE NO DOCUMENTS UPLOADED FOR THIS SECTION YET",
        NO_STUDYING: "THERE ARE NO STUDYING STUDENTS AT THE MOMENT. PLEASE ADD PROGRAMMES THROUGH STUDENTS' PROFILE",
        NO_PR_ARCH: "THERE ARE NO PROGRAMMES FROM THE ARCHIVED STUDENTS",
        NO_COMPLETED: "THERE ARE NO PROGRAMMES HERE AT THE MOMENT",
        NO_EHCP: "THERE IS NO EHCP INFORMATION UPLOADED FOR THIS STUDENT YET",
        NO_NOK: "THERE ARE NO NEXT OF KIN DATA UPLOADED FOR THIS STUDENT YET",
        NO_ASSESSMENT: "THERE IS NO ASSESSMENT INFORMATION FOR THIS STUDENT YET",
        NO_PROGRAMME: "THIS STUDENT IS NOT STUDYING. PLEASE CLICK ON THE BUTTON BELOW TO ADD A PROGRAMME TO THE STUDENT",
        NO_SUPPORT: "THERE IS NO SUPPORT WORKER INFORMATION UPLOADED FOR THIS STUDENT YET",
        NO_TAXI: "THERE IS NO TRAVEL INFORMATION UPLOADED FOR THIS STUDENT YET",
        NO_MEDICAL: "THERE IS NO MEDICAL INFORMATION UPLOADED FOR THIS STUDENT YET",
        NO_CAREERS: "THERE IS NO CAREERS INFORMATION UPLOADED FOR THIS STUDENT YET",
        NO_BURSARY: "THERE IS NO BURSARY INFORMATION UPLOADED FOR THIS STUDENT YET",
        ERROR_MESSAGE: "THERE HAS BEEN AN ERROR DURING THE TRANSACTION. PLEASE TRY AGAIN OR CONTACT THE ADMINISTRATOR",
        UNAUTHORIZED: "YOU ARE NOT AUTHORIZED TO VISIT THE REQUESTED PAGE",
        QUALI_HOURS: "ATTENTION: The total planned hours added per activity",
        QUALI_HOURS_2: "is higher than the qualification hours in the programme",
        QUALI_HOURS_LESS: "The total planned hours added per activity",
        QUALI_HOURS_LESS_2: "is lower than the qualification hours in the programme",
        EEP_HOURS: "ATTENTION: The total of planned EEP hours added per subject",
        EEP_HOURS_2: "is higher than the EEP hours added in the programme",
        EEP_HOURS_LESS: "The total of planned EEP hours added per subject",
        EEP_HOURS_LESS_2: "is lower than the EEP hours added in the programme",
        USE_BUTTON: "Add/Remove columns by using the button at the right"
    },


    VOCATIONAL: {

        FLORITRY: "Floristry",
        HORTI: "Horticulture",
        HOSPI: "Hospitality",
        RETAIL: "Retail",
        RETHOS: "Retail / Hospitality",
        HORTRET: "Horticulture / Retail",
        CUSTOMER: "Customer Service"

    },

    PROGRAM: {
        STUDY: "Study Programme",
        PRESUP: "Pre-Supported Internship",
        NON: "Non-ESFA"
    },

    DESTINATION: {
        PLUS16: "Employment 16+ Hours",
        LESS16: "Employment Fewer than 16 Hours",
        FURTHER: "Further Education (elsewhere)",
        HEALTH: "Health Reason",
        NEET: "NEET",
        RETURNING: "Returning to Roots",
        SUPPORTED: "Supported Internship",
        VOLUNTEERING: "Volunteering",
        RETUR: "Returners",
        LEAVE: "Leavers"

    },


    YEAR: {
        Y20: "2020/2021",
        Y21: "2021/2022",
        Y22: "2022/2023",
        Y23: "2023/2024",
        Y24: "2024/2025",
        Y25: "2025/2026",
        Y26: "2026/2027"
    }

}